import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import gql from "graphql-tag";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";

import Pages from "../../components/Pages";
import { useQuery } from "@apollo/react-hooks";

import { translit, makeUrl, removePreloader, markdownConverter } from "../../helpers";
import { TextBlockMedia, ContentBlock } from "../../components/Blocks";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import EmptyData from "../../components/EmptyData";

export const query = graphql`
	query booksDataQuery($slug: String!) {
		hasura {
			books(where: { slug: { _eq: $slug } }) {
				title_full
				page_title_full
				page_title_short
				slug
				main_image
				main_image_preview
				seo
				content_blocks
			}
		}
	}
`;
const FETCH_QUERY = gql`
	query FetchData($location: String!) {
		books(where: { slug: { _eq: $location } }) {
			title_full
			page_title_full
			page_title_short
			slug
			main_image
			main_image_preview
			seo
			content_blocks
		}
	}
`;

export default function BookPage({ location, data }) {
	const hasuraData = data.hasura;
	const [path] = useState(last(compact(location.pathname.split("/"))));
	const [isMount, setIsMount] = useState(false);
	const [books, setBooks] = useState(get(hasuraData, "books[0]", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount) {
			setBooks(get(fetchData, "books[0]", {}));
		}
		if (error) {
			setBooks(get(hasuraData, "books[0]", {}));
		}

		removePreloader(!loading || error);
	}, [loading]);

	const { content_blocks, page_title_short, page_title_full, main_image_preview, main_image, title_full } = books;

	const url = makeUrl.book(books);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	return books ? (
		<Pages entity={books} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={`Книги / ${title_full}`} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"}>
					{content_blocks &&
						content_blocks.map(({ title, body, media, type }, idx) => {
							const files = get(media, "files", []);

							return (
								<>
									<TextBlockMedia
										key={`${translit(title)}-${idx}`}
										title={title}
										items={media ? media.items : []}
										desc={markdownConverter(body)}
										left={idx % 2 !== 0}
										orientation={type}
									/>
									{files &&
										files.map(({ src, title }, idx) => {
											return (
												<Button
													href={src}
													key={idx}
													className={"button-white mb-5"}
													target={"_blink"}
												>
													{title ? title : "Ознакомительный фрагмент"}
												</Button>
											);
										})}
								</>
							);
						})}
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

BookPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

BookPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
};
